import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { type TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { type ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

interface Striped {
	colorOne: string;
	colorTwo: string;
}

export interface TableProps<T, U = any> {
	data: (T & { inner?: TableProps<U> })[];
	columns: Column<T>[];
	isLoading?: boolean;
	headerHidden?: boolean;
	isInner?: boolean;
	striped?: Striped;
	onRowClick?: (data: T) => void;
}

export interface Column<T> extends Pick<TableCellProps, 'align'> {
	title: string;
	minWidth?: number;
	maxWidth?: number;
	key: keyof T;
	currency?: string;
	totalLabel?: string;
	verticalAlign?: string;
	value: (data: T) => ReactNode;
	sortable?: boolean;
}

export default function Table<T, U = unknown>({
	data,
	columns,
	isLoading = false,
	headerHidden = false,
	striped = {
		colorOne: '#f3f1f0',
		colorTwo: '#fff'
	},
	onRowClick
}: TableProps<T, U>) {
	const [tableData, setTableData] = useState(data);
	const [sortDirection, setSortDirection] = useState(false);
	const [sortKey, setSortKey] = useState<keyof T>();

	function onSort(column: Column<T>) {
		if (!column.sortable) return;
		const key = column.key;
		const direction = !sortDirection;
		if (data[0]) {
			const isNumber = !isNaN(Number(data[0][key] as any));
			const sorted = data.sort((a, b) => {
				const A = a[key] as any;
				const B = b[key] as any;
				if (isNumber) return direction ? B - A : A - B;
				else {
					return direction ? String(B).localeCompare(A) : String(A).localeCompare(B);
				}
			});
			setSortDirection(() => direction);
			setSortKey(key);
			setTableData(() => sorted);
		}
	}

	useEffect(() => {
		setTableData(data);
	}, [data]);
	return (
		<Paper sx={{ width: '100%', height: 'fit-content', position: 'relative' }} elevation={headerHidden ? 0 : 5}>
			<TableContainer sx={{ width: '100%', height: '100%' }}>
				<MuiTable stickyHeader size="small">
					{!headerHidden && (
						<TableHead sx={{ padding: 0 }}>
							<TableRow>
								{columns.map((column, index) => (
									<TableCell
										width={column.maxWidth}
										sx={{
											padding: headerHidden ? 0 : undefined,
											backgroundColor: '#f9f9f9',
											cursor: column.sortable ? 'pointer' : '',
											'&:hover': {
												backgroundColor: '#f1f1f1'
											},
											transition: '0.2s all'
										}}
										key={index}
										align="left"
										onClick={() => onSort(column)}
									>
										<div style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
											{column.title}
											{column.sortable && sortKey === column.key && (sortDirection ? <ExpandMore /> : <ExpandLess />)}
										</div>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{isLoading ? (
							<BodyOverlay>Carregando...</BodyOverlay>
						) : tableData.length === 0 ? (
							<BodyOverlay>
								<td>Nenhum registro encontrado</td>
							</BodyOverlay>
						) : (
							tableData.map((row, index) => {
								return (
									<Row
										key={index}
										{...{
											columns,
											onRowClick,
											row,
											index,
											striped
										}}
									/>
								);
							})
						)}
					</TableBody>
				</MuiTable>
			</TableContainer>
		</Paper>
	);
}

function Row<T, U>({
	columns,
	onRowClick,
	row,
	striped,
	index
}: {
	row: T & { inner?: TableProps<U> };
	onRowClick?: (arg: T) => void;
	columns: Column<T>[];
	index: number;
	striped: Striped;
}) {
	const [collapse, setCollapse] = useState(false);
	const isOdd = index % 2 !== 0;
	const { colorOne, colorTwo } = striped;
	return (
		<>
			<TableRow
				onClick={() => {
					if (onRowClick) onRowClick(row);
					if (row?.inner) setCollapse(prev => !prev);
				}}
				sx={{
					'& > *': row?.inner && { borderBottom: 'unset' },
					'&:hover': {
						cursor: 'pointer',
						filter: 'saturate(130%)'
					},
					backgroundColor: isOdd ? striped.colorOne : striped.colorTwo
				}}
			>
				{columns.map((column, index) => (
					<TableCell
						width={column.maxWidth}
						key={index}
						sx={{
							color: 'inherit',
							textAlign: column.align,
							paddingX: '',
							verticalAlign: column.verticalAlign,
							border: 'none',
							borderBottom: ''
						}}
					>
						{handleColumnValue({
							value: column.value,
							data: row,
							currency: column.currency
						})}
					</TableCell>
				))}
			</TableRow>
			{row?.inner && (
				<TableRow>
					<TableCell
						sx={{
							padding: 0,
							border: 'none',
							borderBottom: ''
						}}
						colSpan={columns.length}
					>
						<Collapse in={collapse} timeout="auto" unmountOnExit>
							<Table
								{...row.inner}
								striped={{ colorOne: isOdd ? colorOne : colorTwo, colorTwo: isOdd ? colorOne : colorTwo }}
							/>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}

function BodyOverlay({ children }: { children: ReactNode }) {
	return <ContainerOverlay>{children}</ContainerOverlay>;
}

const ContainerOverlay = styled.tr`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 40px;
	justify-content: center;
	align-items: center;
`;

function handleColumnValue<T>({ value, data, currency }: Pick<Column<T>, 'value'> & { data: T; currency?: string }) {
	try {
		return currency ? `${currency} ${value(data)}` : value(data);
	} catch (error) {
		console.error(error);
		return '';
	}
}
