import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import Carousel from './components/Carousel';
import LoginForm from './components/LoginForm';
import * as S from './styles';
import ButtonCustom from '../../components/Globals/ButtonCustom';
import Modal from '../../components/Globals/ModalLogin';
import LogoLuvison from '../../components/LogoLuvison';
import { useAppContext } from '../../providers/AppProvider';

const Login: React.FC = () => {
	const [showModalLogin, setShowModalLogin] = useState(false);
	const { user } = useAppContext();

	const handleShowModalLogin = () => {
		setShowModalLogin(true);
	};

	const handleCloseModalLogin = () => {
		setShowModalLogin(false);
	};

	return user ? (
		<Navigate to={'/home'} />
	) : (
		<S.Container>
			{showModalLogin && (
				<Modal onClose={handleCloseModalLogin}>
					<LoginForm hideLogo />
				</Modal>
			)}
			<S.Sidebar>
				<LoginForm />
			</S.Sidebar>
			<S.Carousel>
				<S.ContentMobile>
					<LogoLuvison />
				</S.ContentMobile>
				<Carousel />
				<S.ContentMobile>
					<ButtonCustom
						onClick={() => {
							handleShowModalLogin();
						}}
						variant="contained"
						color="error"
					>
						ACESSAR
					</ButtonCustom>
					{/* 					
					<ButtonCustom variant="outlined" sx={{ color: '#444', borderColor: '#444' }}>
						ACESSAR COMO CONVIDADO
					</ButtonCustom> 
					*/}
				</S.ContentMobile>
			</S.Carousel>
		</S.Container>
	);
};

export default Login;
