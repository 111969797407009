import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-width: 250px;
	min-height: 130px;
	padding: 20px 16px;
	background: '#fff';
	box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	cursor: pointer;
`;

export const Title = styled.h2`
	font-size: 20px;
	margin: 0;
`;

export const Description = styled.h2`
	font-size: 18px;
	font-weight: 1;
	margin: 0;
`;

export const Code = styled.h2`
	font-size: 14px;
	font-weight: 1;
	margin: 0;
	color: #999;
`;
