import { doc, getDoc } from 'firebase/firestore';

import type { GlobalConfig } from '../assets/@types/config';
import db from '../config/firebaseDb';

export async function getConfig() {
	try {
		await setGlobalConfig();
	} catch (error) {
		console.error(error);
	}
}

async function setGlobalConfig() {
	const docRef = doc(db, 'SysParam', 'Global');

	const snap = await getDoc(docRef);

	if (!snap.exists()) return;

	localStorage.setItem(snap.id, JSON.stringify(snap.data()));

	return snap.data() as GlobalConfig;
}

export function getLocalGlobalConfig() {
	const json = localStorage.getItem('Global');
	if (!json) return;
	try {
		const localConfig = JSON.parse(json) as GlobalConfig;
		return localConfig;
	} catch (error) {
		console.error('Não foi possível encontrar a configuração local');
	}
}
