import { Alert, Box } from '@mui/material';
import { format } from 'date-fns';
import { addDoc, collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import * as S from './styles';
import RequestServiceModalConfirm from '../RequestServiceModalConfirm';
import ButtonCustom from '../../../../components/Globals/ButtonCustom';
import InputCustom from '../../../../components/Globals/InputCustom';
import Modal from '../../../../components/Globals/Modal';
import RadioGroupCustom from '../../../../components/Globals/RadioGroupCustom';
import SelectCustom from '../../../../components/Globals/SelectCustom';
import TextAreaCustom from '../../../../components/Globals/TextAreaCustom';
import { UploadFile, UploadForm } from '../../../../components/Globals/UploadForm/UploadForm';
import db from '../../../../config/firebaseDb';
import { limiteSizeBytes, limiteSizeMegabytes } from '../../../../constants/upload.constants';
import { criarMensagemAnexo } from '../../../../services/chatServices';
import { useUserAuth } from '../../../../services/userAuth';
import { optionsPriority, optionsServices } from '../../../../utils/mockup';

interface dataPropsCli {
	id: string;
	label: string;
	name: string;
	astreaCod: string;
}

const RequestServiceForm: React.FC = () => {
	const user = useUserAuth();
	const [showModalConfirmRequest, setShowModalConfirmRequest] = useState(false);
	// const [isLoading, setIsLoading] = useState(true);
	// const [open, setOpen] = useState(false);
	const [messageErr, setMessageErr] = useState('');
	const [messageOk, setMessageOk] = useState('');
	const [protocolo, setProtocolo] = useState('');
	const [assunto, setAssunto] = useState('');
	const [prioridadeId, setPrioridadeId] = useState('');
	const [prioridade, setPrioridade] = useState('');
	const [servicoDes, setServicoDes] = useState('');
	const [tipoServicoId, setTipoServicoId] = useState('');
	const [tipoServico, setTipoServico] = useState('');
	const [anexos, setAnexos] = useState<UploadFile[]>([]);

	const [listCli, setListCli] = useState<dataPropsCli[]>([]);
	const [selectedCli, setSelectedCli] = useState('');
	const [selectedCliId, setSelectedCliId] = useState('');

	const totalBytes = anexos.reduce((total, { arquivo }) => total + arquivo.size, 0);
	const isOverLimit = totalBytes > limiteSizeBytes;

	// const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
	// 	if (reason === 'clickaway') {
	// 		return;
	// 	}
	// 	setOpen(false);
	// };

	async function mensagemAnexo(docId: string) {
		if (user) {
			await criarMensagemAnexo(anexos, docId, user);
		}
	}

	const enviarSolicitacao = async () => {
		try {
			// setIsLoading(true);
			setMessageErr('');

			const protocol = format(new Date().getTime(), 'yyMMddHHmmss');
			const UidSolicitacao = user != undefined ? user.uid : '';
			const DthSolicitacao = new Date();

			const oContact = {
				Origem: 'Web',
				Email: user?.email ?? '',
				Nome: user?.displayName ?? '',
				ClienteId: selectedCliId,
				Cliente: selectedCli,
				ClienteDoc: '',
				Telefone: '',
				Assunto: assunto,
				TipoServico: tipoServico, //novo
				TipoServicoId: tipoServicoId, //novo
				StatusId: '1',
				StatusDes: 'Nova',
				PrioridadeId: prioridadeId,
				Prioridade: prioridade,
				ServicoDes: servicoDes,
				Protocolo: protocol,
				UidSolicitacao,
				DthSolicitacao,
				UidRegistro: UidSolicitacao, //Para Log Padrão
				DthRegistro: DthSolicitacao //Para Log Padrão
			};

			if (!isValid()) return false;
			const docRef = await addDoc(collection(db, 'AppContato'), oContact);
			setMessageOk('Dados enviados com sucesso - Protocolo: ' + protocol);
			//setOpen(true);
			setProtocolo(protocol);
			setShowModalConfirmRequest(true);
			return docRef.id;
		} catch (error: any) {
			console.error(error);
			setMessageErr(error?.message ?? 'Ocorreu um erro interno! Contate o Administrador.');
		} finally {
			// setIsLoading(false);
		}
	};

	const isValid = () => {
		let msg = '';
		// const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		// if (name.trim().length < 5) msg += '\u2B24 Nome completo inválido!\n';
		// if (!regEmail.test(email)) msg += '\u2B24 E-mail inválido!\n';
		if (!selectedCliId) msg += '\u2B24 Informe uma Empresa!\n';
		if (!tipoServico) msg += '\u2B24 Informe um Tipo de Serviço!\n';
		if (!prioridade) msg += '\u2B24 Informe uma Prioridade!\n';
		if (assunto.trim().length < 3) msg += '\u2B24 Assunto inválido! (mínimo 3 letras)\n';
		if (servicoDes.trim().length < 5) msg += '\u2B24 Mensagem inválida! (mínimo 5 letras)\n';
		if (isOverLimit) msg += `\u2B24 O total permitido para anexo(s) é de ${limiteSizeMegabytes}MB\n`;
		setMessageErr(msg);
		if (msg != '') return false;
		else return true;
	};

	const handleCloseModalConfirmRequest = () => {
		setShowModalConfirmRequest(false);
	};

	const handleSend = async () => {
		const docId = await enviarSolicitacao();
		if (docId) {
			await mensagemAnexo(docId);
		}
	};

	const fetchDataCli = async () => {
		if (user?.uid) {
			const q = query(
				collection(db, 'Cliente'),
				where('Usuario', 'array-contains', user?.uid),
				orderBy('NomeEmpresa', 'asc')
			);
			try {
				const querySnap = await getDocs(q);
				const dataCli: dataPropsCli[] = [];
				querySnap.forEach(doc => {
					const { NomeApelido, NomeEmpresa, AstreaCod } = doc.data();
					dataCli.push({
						id: doc.id,
						label: NomeApelido ? NomeApelido : NomeEmpresa,
						name: NomeApelido ? NomeApelido : NomeEmpresa,
						astreaCod: AstreaCod
					});
				});
				setListCli(dataCli);
			} catch (error) {
				console.error('[fetchDataCli] Err: ', error);
			}
		}
	};

	useEffect(() => {
		fetchDataCli();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<>
			{showModalConfirmRequest && (
				<Modal onClose={handleCloseModalConfirmRequest}>
					<RequestServiceModalConfirm Protocolo={protocolo} Assunto={assunto} />
				</Modal>
			)}
			<S.Container>
				<SelectCustom
					externallabel="SELECIONE O VÍNCULO"
					id={selectedCliId}
					setId={setSelectedCliId}
					value={selectedCli}
					setValue={setSelectedCli}
					data={listCli}
				/>

				<SelectCustom
					externallabel="TIPO DE SERVIÇO"
					id={tipoServicoId}
					setId={setTipoServicoId}
					value={tipoServico}
					setValue={setTipoServico}
					data={optionsServices}
				/>

				<RadioGroupCustom
					externallabel="PRIORIDADE"
					value={prioridade}
					options={optionsPriority}
					onChangeValue={function (e): void {
						setPrioridadeId(e.value);
						setPrioridade(e.label);
					}}
				/>
				<InputCustom
					externallabel="ASSUNTO"
					placeholder="Ex: Contrato para fornecedor"
					onChange={e => setAssunto(e.target.value)}
				/>
				<TextAreaCustom
					customlabel="DESCREVA A SOLICITAÇÃO"
					placeholder="Ex: Descreva sua solicitação aqui!"
					onChange={e => setServicoDes(e.target.value)}
				/>
				<UploadForm setFile={setAnexos} arquivos={anexos} isOverLimit={isOverLimit} totalBytes={totalBytes} />

				{messageErr && (
					<Alert severity="error" sx={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
						<div dangerouslySetInnerHTML={{ __html: messageErr.replace(/\n/g, '<br/>') }} />
					</Alert>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<ButtonCustom
						variant="contained"
						color="error"
						sx={{ width: '350px' }}
						onClick={() => {
							handleSend();
						}}
						disabled={messageOk ? true : false}
					>
						ENVIAR
					</ButtonCustom>
					{/* Snackbar para exibir o Toast */}
					{/* 
					<Snackbar
						open={open}
						autoHideDuration={5000}
						onClose={handleClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					>
						<MuiAlert elevation={6} variant="filled" severity="success">
							{messageOk}
						</MuiAlert>
					</Snackbar>
					 */}
				</Box>
			</S.Container>
		</>
	);
};

export default RequestServiceForm;
