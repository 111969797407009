import { ref } from '@firebase/storage';
import { Box, Typography } from '@mui/material';
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { getDownloadURL } from 'firebase/storage';
import React, { useEffect, useState } from 'react';

import * as S from './styles';
import CardListItem from '../../../../components/CardListItem';
import SelectCustom from '../../../../components/Globals/SelectCustom';
import db, { auth, storage } from '../../../../config/firebaseDb';

interface dataPropsCli {
	id: string;
	label: string;
	name: string;
	astreaCod: string;
}

interface DocumentData {
	id: string;
	Descricao: string;
	FileExt: string;
	FileName: string;
	ClienteId: string;
}

const DocumentsForm: React.FC = () => {
	const [user, setUser] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [listDoc, setListDoc] = useState<DocumentData[]>([]);
	const [listCli, setListCli] = useState<dataPropsCli[]>([]);
	const [selectedCli, setSelectedCli] = useState('');
	const [selectedCliId, setSelectedCliId] = useState('');

	// const openUrl = async url => {
	// 	try {
	// 		await Linking.canOpenURL(url) //verifica se é válido/se pode abrir
	// 		Linking.openURL(url)
	// 	} catch (error) {
	// 		console.warn(error)
	// 		alert(error.message)
	// 	}
	// }

	const openFile = async (item: any) => {
		const { id, ClienteId, FileExt } = item;
		const filePath = `/Documentos/${ClienteId}/${id}.${FileExt}`;
		const fileRef = ref(storage, filePath);
		getDownloadURL(fileRef)
			.then((url: string) => {
				//openUrl(url);
				//if (item.code)
				window.open(url, '_blank');
			})
			.catch(function (error: any) {
				console.error(error);
				// https://firebase.google.com/docs/storage/web/handle-errors
				// switch (error.code) {
				// 	case 'storage/object-not-found':
				// 		Alert.alert('Atenção', 'Arquivo não localizado!')
				// 		break
				// 	case 'storage/unauthorized':
				// 		Alert.alert('Atenção', 'Sem autorização!')
				// 		break
				// 	case 'storage/canceled':
				// 		Alert.alert('Atenção', 'Arquivo cancelado!')
				// 		break
				// 	case 'storage/unknown':
				// 		Alert.alert('Atenção', 'Erro desconhecido: ' + error.code, 'Atenção')
				// 		break
				// }
			});
	};

	const fetchDataCli = async () => {
		if (user?.uid) {
			const q = query(
				collection(db, 'Cliente'),
				where('Usuario', 'array-contains', user?.uid),
				orderBy('NomeEmpresa', 'asc')
			);
			try {
				const querySnap = await getDocs(q);
				const dataCli: dataPropsCli[] = [];
				querySnap.forEach(doc => {
					const { NomeApelido, NomeEmpresa, AstreaCod } = doc.data();
					dataCli.push({
						id: doc.id,
						label: NomeApelido ? NomeApelido : NomeEmpresa,
						name: NomeApelido ? NomeApelido : NomeEmpresa,
						astreaCod: AstreaCod
					});
				});
				setListCli(dataCli);
			} catch (error) {
				console.error('[fetchDataCli] Err: ', error);
			}
		}
	};

	useEffect(() => {
		fetchDataCli();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setUser(user);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		setIsLoading(true);
		let subscrib = function () {};
		try {
			// console.log('carregando documentos...');
			if (user == undefined || user == null) return;

			if (!selectedCli) return;
			let clienteId = '';
			const selectedEmp = listCli.find(item => item.id === selectedCliId);
			if (selectedEmp) clienteId = selectedEmp.id;
			if (!clienteId) return;
			// console.log('Cliente >>>> ' + clienteId);

			const q = query(collection(db, 'Documento'), where('ClienteId', '==', clienteId));
			subscrib = onSnapshot(q, querySnapshot => {
				console.info('Documento >> UPDATE LISTENER');
				const data: DocumentData[] = [];
				querySnapshot.forEach(doc => {
					const oItem: DocumentData = {
						id: doc.id,
						Descricao: doc.data().Descricao || '',
						FileExt: doc.data().FileExt || '',
						FileName: doc.data().FileName || '',
						ClienteId: doc.data().ClienteId || ''
					};
					data.push(oItem);
				});
				setListDoc(data);
			});
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}

		// cancel subscription to useEffect
		return () => {
			subscrib();
			console.warn('<<< [Document.List] Cancel Listner >>>');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, selectedCli]);

	//-----------------------------------//-----------------------------------

	return (
		<S.Container>
			<SelectCustom
				externallabel="SELECIONE O VÍNCULO"
				id={selectedCliId}
				setId={setSelectedCliId}
				value={selectedCli}
				setValue={setSelectedCli}
				data={listCli}
			/>

			<>{isLoading}</>

			{listDoc.length > 0
				? listDoc.map((document, index) => (
						<React.Fragment key={index}>
							<CardListItem
								id={document.id}
								title={'Descrição: ' + document.Descricao}
								// description={'Arquivo: ' + document.FileName}
								code={'Arquivo: ' + document.FileName}
								document={document ? true : false}
								handleClick={function (): void {
									openFile(document);
								}}
							/>
						</React.Fragment>
				  ))
				: selectedCli && (
						<Box justifyContent={'center'} alignItems={'center'}>
							<Typography>Nenhum registro encontrado</Typography>
						</Box>
				  )}
		</S.Container>
	);
};

export default DocumentsForm;
