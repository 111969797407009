import { Box, Typography } from '@mui/material';
import { onMessage } from 'firebase/messaging';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import SvgIcon from '../Globals/SvgIcon';
import { LayoutBaseContext } from '../LayoutBase';
import { messaging } from '../../config/firebaseDb';
import { useAppContext } from '../../providers/AppProvider';
import { signOut } from '../../services/userAuth';
import { menuSidebar } from '../../utils/mockup';
import { requestPermission } from '../../utils/requestNotification';
//import { signOut } from '../../services/userAuth';

const Sidebar = () => {
	const [userEmail, setUserEmail] = useState('');
	const [userName, setUserName] = useState('');
	const { user } = useAppContext();
	const { showSidebar } = useContext(LayoutBaseContext);
	const navigate = useNavigate();

	const navigateTo = (link: string) => {
		navigate(link);
	};

	useEffect(() => {
		if (user) {
			setUserEmail(user?.email || '');
			setUserName(user?.displayName || '');
			requestPermission(user.uid).then(() => {
				onMessage(messaging, payload => {
					console.info('Message received. ', payload);
				});
			});
		}
	}, []);

	return (
		<S.Container open={showSidebar}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '20px',
					margin: '10px 0'
				}}
			>
				<S.WrapperLogoMarum>
					<SvgIcon path="/images/icons/IconProfile.svg" size={38} />
				</S.WrapperLogoMarum>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '6px'
					}}
				>
					<Typography
						variant="h2"
						sx={{
							fontSize: '18px',
							fontWeight: '500'
						}}
					>
						{userName}
					</Typography>
					<Typography
						variant="h3"
						sx={{
							fontSize: '13px'
						}}
					>
						{userEmail}
					</Typography>
				</Box>
			</Box>
			<S.Hr />
			{menuSidebar.map(item => (
				<React.Fragment key={item.id}>
					<S.WrapperButton onClick={() => navigateTo(item.link)}>
						<SvgIcon path={item.icon} size={24} />
						<Typography
							variant="h3"
							sx={{
								fontSize: '16px',
								fontWeight: '500'
							}}
						>
							{item.title}
						</Typography>
					</S.WrapperButton>
				</React.Fragment>
			))}
			<S.Hr />
			<a
				href="https://api.whatsapp.com/send/?phone=551533579666&text&type=phone_number&app_absent=0"
				target="_blank"
				rel="noopener noreferrer"
				style={linkStyle}
			>
				<S.WrapperButton>
					<SvgIcon path="/images/icons/IconChat.svg" size={24} />
					<Typography
						variant="h3"
						sx={{
							fontSize: '16px',
							fontWeight: '500'
						}}
					>
						Fale conosco
					</Typography>
				</S.WrapperButton>
			</a>

			<S.WrapperButton onClick={signOut}>
				<SvgIcon path="/images/icons/IconLeave.svg" size={24} />
				<Typography
					variant="h3"
					sx={{
						fontSize: '16px',
						fontWeight: '500'
					}}
				>
					Sair
				</Typography>
			</S.WrapperButton>
		</S.Container>
	);
};

export default Sidebar;

const linkStyle = {
	textDecoration: 'none', // Remove o sublinhado
	color: 'inherit', // Usa a cor padrão do texto do pai para os links
	cursor: 'pointer' // Altera o cursor para indicar que é clicável
};
