import { Alert, Box, Link } from '@mui/material';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { auth } from './../../../../config/firebaseDb';
import * as S from '../../styles';
import ButtonCustom from '../../../../components/Globals/ButtonCustom';
import InputCustom from '../../../../components/Globals/InputCustom';
import Loading from '../../../../components/Loading';
import LogoLuvison from '../../../../components/LogoLuvison';
import { appVersion } from '../../../../constants/app.constants';
import { getConfig } from '../../../../services/configService';

interface LoginFormProps {
	hideLogo?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ hideLogo }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [alertMessage, setAlertMessage] = useState('');
	const afterLoginRoute = location.state?.from?.pathname ?? '/home';

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') handleLogin();
	};

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleLogin = async () => {
		if (!email.trim() || !password.trim()) {
			setAlertMessage('Digite seu e-mail e senha!');
			return;
		}

		try {
			setLoading(true);
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			//console.log('Usuário logado >>> ', userCredential.user.uid);
			if (userCredential.user) {
				getConfig();
				localStorage.setItem('userEmail', userCredential.user.email ?? '-');
				localStorage.setItem('userName', userCredential.user.displayName ?? '-');
			} else {
				localStorage.removeItem('userEmail');
				localStorage.removeItem('userName');
			}
			setTimeout(() => {
				setLoading(false);
				navigate(afterLoginRoute);
			}, 1000);
		} catch (error) {
			setLoading(false);
			console.error(error);
			// Trate o erro aqui
			setAlertMessage('E-mail e/ou senha inválidos!');
		}
	};

	const handleEsqueceuSenhaClick = () => {
		if (!email.trim()) {
			console.warn('Informe seu e-mail para redefinirmos sua senha!');
			setAlertMessage('Informe seu e-mail para redefinirmos sua senha!');
			return;
		}

		sendPasswordResetEmail(auth, email)
			.then(() => {
				console.warn('Enviamos um link de recuperação para seu e-mail!');
				setAlertMessage('Enviamos um link de recuperação para seu e-mail!');
			})
			.catch(error => {
				console.error(error);
				setAlertMessage('Erro! ' + error);
			});
	};

	return (
		<>
			<Loading open={loading} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%'
				}}
			>
				{!hideLogo && <LogoLuvison />}
				<S.WrapperInfo isMobile={hideLogo}>
					<h2>Área exclusiva para clientes</h2>
					<S.Row>
						<S.WrapperIconPlus>
							<img src="/images/iconPlus.svg" alt="" />
						</S.WrapperIconPlus>
						<S.Column>
							<span>Se você ainda não é nosso cliente,</span>
							<span>
								<b>fale com um de nossos advogados</b>
							</span>
							<span>e tenha sua empresa protegida</span>
						</S.Column>
					</S.Row>
				</S.WrapperInfo>
				<S.Form isMobile={hideLogo}>
					<h1>ACESSO</h1>
					<InputCustom
						label="Digite seu e-mail de login"
						fullWidth
						id="username"
						name="username"
						autoComplete="username"
						value={email}
						onChange={handleEmailChange}
						onKeyDown={handleKeyPress}
						marginBottom="10px"
						InputLabelProps={{ shrink: true }}
					/>

					<InputCustom
						label="Senha"
						type="password"
						id="password"
						name="password"
						autoComplete="current-password"
						value={password}
						onChange={handlePasswordChange}
						onKeyDown={handleKeyPress}
						marginBottom="10px"
						InputLabelProps={{ shrink: true }}
					/>

					{/* <FormControlLabel
						label="Lembrar acesso"
						control={<Checkbox value="" color="primary" />}
						sx={{
							alignSelf: 'flex-start',
							margin: 0,
							padding: 0
						}}
					/> */}

					{/* Mostra o alerta se houver uma mensagem */}
					{alertMessage && (
						<Alert severity="error" sx={{ marginTop: '10px' }}>
							{alertMessage}
						</Alert>
					)}

					<ButtonCustom
						variant="contained"
						color="error"
						sx={{
							marginTop: hideLogo ? '10px' : '20px',
							marginBottom: '10px'
						}}
						onClick={() => {
							handleLogin();
						}}
					>
						ENTRAR
					</ButtonCustom>

					<ButtonCustom style={{ color: 'red', cursor: 'pointer' }} onClick={handleEsqueceuSenhaClick}>
						Esqueceu a senha?
					</ButtonCustom>
				</S.Form>
				<Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'end'} flexDirection={'column'}>
					<Link
						color={'#c5c5c5'}
						sx={{ textAlign: 'center', margin: 0 }}
						href={'https://www.netcreator.com.br/?cli=luvison'}
						target={'_blank'}
						rel={'noopener'}
					>
						Desenvolvido por NetCreator
					</Link>
					<p style={{ color: '#c5c5c5', fontSize: 10, margin: 0, marginTop: 2 }}>Versão {appVersion}</p>
				</Box>
			</Box>
		</>
	);
};

export default LoginForm;
