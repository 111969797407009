import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

// import { useNavigate } from 'react-router-dom';
import { ClientData } from './../../../../utils/typesData';
import * as S from './styles';
import CardListItem from '../../../../components/CardListItem';
import Loading from '../../../../components/Loading';
import db, { auth } from '../../../../config/firebaseDb';

const CustomerList: React.FC = () => {
	const [user, setUser] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState<ClientData[]>([]);

	// const navigate = useNavigate();

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setUser(user);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		setLoading(true);
		let subscrib = function () {};
		try {
			if (user == undefined || user == null) return;

			const q = query(
				collection(db, 'Cliente'),
				where('Usuario', 'array-contains', user?.uid),
				orderBy('NomeEmpresa', 'asc')
			);
			subscrib = onSnapshot(q, querySnapshot => {
				// console.log('Documento >> UPDATE LISTENER');
				const data: ClientData[] = [];
				querySnapshot.forEach(doc => {
					// console.log(doc.data());
					const oItem: ClientData = {
						id: doc.id,
						NomeEmpresa: doc.data().NomeEmpresa || doc.data().NomeApelido,
						AstreaLink: doc.data().AstreaLink || '-'
					};
					data.push(oItem);
				});
				setList(data);
			});
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}

		// cancel subscription to useEffect
		return () => {
			subscrib();
			console.info('<<< [Request.List] Cancel Listner >>>');
		};
	}, [user]);

	//-----------------------------------//-----------------------------------

	return (
		<>
			<Loading open={loading} />
			<S.Container>
				{list.map(item => (
					<React.Fragment key={item.id}>
						<CardListItem
							title={item.NomeEmpresa}
							code={item.AstreaLink || '-'}
							id={item.id}
							handleClick={function (item): void {
								if (item.code) window.open(item.code, '_blank');
							}}
						/>
					</React.Fragment>
				))}
			</S.Container>
		</>
	);
};

export default CustomerList;
