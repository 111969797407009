import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import App from './App';
import ProtectedRoute from './components/ProtectedRoute';
import ConsultRequest from './pages/ConsultRequest';
import Contact from './pages/Contact';
import ContactDetails from './pages/ContactDetails';
import Documents from './pages/Documents';
import ErrorPage from './pages/ErrorPage';
import Home from './pages/Home';
import Lawsuits from './pages/Lawsuits';
import Login from './pages/Login';
import ProcedureItem from './pages/ProcedureItem';
import Procedures from './pages/Procedures';
import ReportProcedure from './pages/ReportProcedure/ReportProcedure';
import RequestItem from './pages/RequestItem';
import RequestService from './pages/RequestService';
import { AppProvider } from './providers/AppProvider';

const protectedRoutes = [
	{
		path: '/home',
		element: <Home />
	},
	{
		path: '/contact',
		element: <Contact />
	},
	{
		path: '/service_request',
		element: <RequestService />
	},
	{
		path: '/consult_request',
		element: <ConsultRequest />
	},
	{
		path: '/documents',
		element: <Documents />
	},
	{
		path: '/procedures',
		element: <Procedures />
	},
	{
		path: '/lawsuits',
		element: <Lawsuits />
	},
	{
		path: '/report-lawsuits',
		element: <ReportProcedure />
	},
	// nested routes - indentificaor único
	{
		path: '/request_item/:id',
		element: <ProtectedRoute element={<RequestItem />} />
	},
	{
		path: '/procedure_item/:id',
		element: <ProcedureItem />
	},
	{
		path: '/contact/:id',
		element: <ContactDetails />
	},
	{
		path: 'oldcontact',
		element: <Navigate to="/contact" />
	}
];

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AppProvider>
				<App />
			</AppProvider>
		),
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: <Login />
			},
			...protectedRoutes.map(({ path, element }) => ({ path, element: <ProtectedRoute element={element} /> }))
		]
	}
]);
